import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

// import './App.css';

import NavBar from './components/layouts/NavBar';
import Diamonds from './app/diamond/page';
import DiamondsByAddress from './app/address/page';
import Bidding from "./app/bidding/page";
import HACDSet from "./app/hacdset/page";


function App() {
  const currentYear = new Date().getFullYear();
  return (
    <Router>
      <div className='flex flex-col min-h-screen'>

        <NavBar/>

        <div className='flex-1 bg-black'>
          <Routes>
            <Route path='*' element={<DiamondsByAddress/>} />
            <Route path='/report' element={<Diamonds/>} />
            <Route path="/bidding" element={<Bidding />} />
            <Route path="/hacdset" element={<HACDSet />} />
          </Routes>
        </div>

        <div className='bg-back-footer py-2 sm:py-5 bottom-0 z-10'>
          <h1 className='text-white text-sm sm:text-[18px] text-center'>© Copyright {currentYear} hacpool.com - All Rights Reserved</h1>
        </div>
      </div>
    </Router>
  );
}

export default App;
