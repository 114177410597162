import { CreateDiamondImageTagSVG } from '../../scripts/diamondsvgimg.js';

interface Diamond {
  number: string;
  name: string;
  visual_gene: string;
  fee: string;
}

interface PictureViewerProps {
  diamonds: Diamond[];
  backgroundColor: string;
  copyrightText: string;
  contactInfo: string;
  nameColor: string;
}

export function PictureViewer({ diamonds, backgroundColor, copyrightText, contactInfo, nameColor }: PictureViewerProps) {

    return (
        <div id="diamond-viewer"
             className="w-full max-w-[1280px] mx-auto flex flex-col justify-between select-none"
             style={{ backgroundColor }}
        >
            {/* Header - 适当增加 padding */}
            <header className="flex items-center p-3 md:p-4 bg-gray-900 relative">
                {/* Logo - 增大尺寸 */}
                <div className="w-8 h-8 md:w-10 md:h-10 rounded-full border-2 border-yellow-500/80 flex items-center
                justify-center text-yellow-500/80 text-xl md:text-2xl font-bold relative ml-2 md:ml-6">
                    <span className="absolute"
                          style={{top: '55%', left: '50%', transform: 'translate(-50%, -50%)'}}>ㄜ</span>
                </div>

                {/* Title - 增加宽度和字体大小 */}
                <div className="absolute left-1/2 -translate-x-1/2 w-[160px] md:w-[200px] text-center">
                    <h1 className="text-2xl md:text-4xl font-extrabold tracking-widest
                                 bg-gradient-to-br from-yellow-400 via-yellow-200 to-yellow-500
                                 bg-clip-text text-transparent
                                 drop-shadow-[0_0_10px_rgba(234,179,8,0.3)]
                                 filter saturate-150">
                        HACD
                    </h1>
                    <p className="text-xs md:text-sm font-medium
                                bg-gradient-to-r from-gray-400 to-gray-300
                                bg-clip-text text-transparent
                                tracking-[0.2em]">
                        Hacash Diamond
                    </p>
                </div>

                {/* Contact Info - 增加字体大小 */}
                <div className="ml-auto mr-2 md:mr-6 text-gray-400/80 text-xs md:text-base">
                    {contactInfo}
                </div>
            </header>

            {/* Diamond Grid - 增加间距和内边距 */}
            <div className="grid grid-cols-3 gap-2 md:gap-6 p-2 md:p-4">
                {diamonds.map((diamond) => (
                    <div
                        key={diamond.number}
                        className="flex flex-col items-center justify-center gap-2 md:gap-3 p-2 md:p-3 rounded-lg transition-colors"
                    >
                        {/* Diamond Shape Container - 增大尺寸 */}
                        <div className="w-24 h-24 sm:w-32 sm:h-32 md:w-40 md:h-40 lg:w-48 lg:h-48 relative">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: CreateDiamondImageTagSVG(diamond.visual_gene, '100%')
                                }}
                                className="w-full h-full"
                            />
                        </div>

                        {/* Diamond Info - 增加字体大小和间距 */}
                        <div className="text-center">
                            <h2 className="text-lg md:text-2xl font-semibold" style={{ color: nameColor }}>{diamond.name}</h2>
                            <p className="text-sm md:text-lg text-gray-600"># {diamond.number}</p>
                            <p className="text-xs md:text-base text-gray-500">fee: ㄜ {diamond.fee}</p>
                        </div>
                    </div>
                ))}
            </div>

            {/* Footer - 增加 padding */}
            <footer className="p-3 md:p-4 bg-gray-900 text-center">
                <p className="text-gray-400/80 text-xs md:text-base">{copyrightText}</p>
            </footer>
        </div>
    )
}
