import React, { useState, useEffect } from "react";
import { Button, Input, Spinner } from "@material-tailwind/react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

declare global {
  interface Window {
    MoneyNex?: {
      raisefee: (params: { hash: string; fee: string }, callback: (result: any) => void) => void;
    };
  }
}

const QuoteFee = () => {
    const [txhash, setTxHash] = useState<string>('')
    const [fee, setFee] = useState<string>('')
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isPluginAvailable, setIsPluginAvailable] = useState<boolean>(false)

    useEffect(() => {
        const checkPlugin = () => {
            if (window.MoneyNex) {
                setIsPluginAvailable(true);
            } else {
                setTimeout(checkPlugin, 1000);
            }
        };
        checkPlugin();
    }, []);

    const handleResetFee = async () => {
        if (txhash === "" || fee === "") {
            setLoading(false)
            toast.error("Required Input values...");
            return;
        }

        if (!window.MoneyNex) {
            setLoading(false)
            toast.error("MoneyNex plugin not found. Please install the plugin and refresh the page.");
            return;
        }

        window.MoneyNex.raisefee({ hash: txhash, fee }, (result) => {
            setLoading(false)
            if (result.ret === 0 && result.success) {
                toast.success("Fee raised successfully");
            } else {
                toast.error("Failed to raise fee: " + (result.error || "Unknown error"));
            }
        });
    }

    return (
        <div className="container px-[10px] sm:px-[5px] pt-2 sm:pt-10">
            <div className="mx-auto mt-5 sm:mt-10 bg-gradient-to-r from-[#bb71ff] to-[#924dea] text-white rounded">
                <h1 className="text-[14px] text-lg p-2 sm:p-4">Fee competition:</h1>
                <p className="p-2 sm:p-4 text-[12px]">
                    Reset and increase the fee of the transaction to be confirmed in the transaction pool to advance the queuing position of the transaction and get the confirmation earlier. It can also be used for the commission price to be confirmed after diamond excavation.
                </p>
                <div className="p-2 sm:p-4">
                    <div className="mb-3 sm:mb-8 flex flex-col gap-6">
                        <Input
                            color="white"
                            size="lg"
                            label="TxHash"
                            value={txhash}
                            onChange={(event) => setTxHash(event.target.value)}
                            crossOrigin={undefined}
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        />
                        <Input
                            color="white"
                            size="lg"
                            label="Reset fee"
                            value={fee}
                            onChange={(event) => setFee(event.target.value)}
                            crossOrigin={undefined}
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        />
                    </div>
                    <div>
                        <Button
                            onClick={handleResetFee}
                            variant="outlined"
                            className="flex flex-row items-center"
                            disabled={isLoading || !isPluginAvailable}
                            color="white"
                            placeholder={undefined}
                            onPointerEnterCapture={() => {}}
                            onPointerLeaveCapture={() => {}}
                        >
                            {isPluginAvailable ? "Confirm Reset Fee" : "MoneyNex Plugin Not Detected"}
                            {isLoading &&
                                <Spinner
                                    className="h-4 w-4 ml-2"
                                    onPointerEnterCapture={() => {}}
                                    onPointerLeaveCapture={() => {}}
                                />
                            }
                        </Button>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuoteFee;
