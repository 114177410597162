import { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react'; // 需要安装 lucide-react

interface ControlPanelProps {
  onExport: (backgroundColor: string) => void;
  onColorChange: (color: string) => void;
  onDiamondsInput: (diamonds: string[]) => void;
  onCopyrightChange: (text: string) => void;
  onContactChange: (text: string) => void;
  onNameColorChange: (color: string) => void;
}

const PRESET_COLORS = [
  '#ffffff', // 纯白
  '#f8fafc', // 浅灰白
  '#f1f5f9', // 更浅的灰
  '#fef3c7', // 浅黄
  '#fef2f2', // 浅红
  '#f0fdf4', // 浅绿
  '#f0f9ff', // 浅蓝
  '#faf5ff', // 浅紫
  '#fdf4ff', // 浅粉
  '#f7fee7', // 浅青
];

const DEFAULT_DIAMONDS = [
  "IUHYNH", "IHEBAN", "IZXYSS",
  "IUXBXT", "IXMMSM", "IUVKES",
  "IWXXYK", "IMTKHM", "IAHXAY"
];
const DEFAULT_DIAMONDS_NULL = [
  "", "", "",
  "", "", "",
  "", "", ""
];

// 添加名称颜色预设
const PRESET_NAME_COLORS = [
  '#1d4ed8', // 蓝色
  '#047857', // 绿色
  '#7c3aed', // 紫色
  '#b91c1c', // 红色
  '#c2410c', // 橙色
  '#0369a1', // 天蓝
  '#4338ca', // 靛蓝
  '#be185d', // 粉红
  '#1e293b', // 深灰
  '#92400e', // 棕色
];

export function ControlPanel({ onExport, onColorChange, onDiamondsInput, onCopyrightChange, onContactChange, onNameColorChange }: ControlPanelProps) {
  const [position, setPosition] = useState({ x: 20, y: 20 });
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [bgColor, setBgColor] = useState('#ffffff');
  const panelRef = useRef<HTMLDivElement>(null);
  const [diamondInputs, setDiamondInputs] = useState<string[]>(DEFAULT_DIAMONDS);
  const [copyrightText, setCopyrightText] = useState('© 2024 Hacash Diamond. All rights reserved.');
  const [contactInfo, setContactInfo] = useState('');
  const [nameColor, setNameColor] = useState('#1d4ed8');
  const [isColorSectionCollapsed, setIsColorSectionCollapsed] = useState(true);

  useEffect(() => {
    // 初始加载默认钻石
    onDiamondsInput(DEFAULT_DIAMONDS);
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (panelRef.current) {
      setIsDragging(true);
      const rect = panelRef.current.getBoundingClientRect();
      setDragOffset({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top
      });
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (isDragging) {
        setPosition({
          x: e.clientX - dragOffset.x,
          y: e.clientY - dragOffset.y
        });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragOffset]);

  const handleColorUpdate = (color: string) => {
    setBgColor(color);
    onColorChange(color);
  };

  const handleDiamondChange = (index: number, value: string) => {
    const newDiamonds = [...diamondInputs];
    newDiamonds[index] = value.trim().toUpperCase();
    setDiamondInputs(newDiamonds);
  };

  const handleLoadDiamonds = (e: React.MouseEvent | React.TouchEvent) => {
    // 阻止默认事件
    e.preventDefault();
    e.stopPropagation();

    let inputDiamonds = diamondInputs.filter(input => input !== "");

    onDiamondsInput(inputDiamonds);
  };

  const handleCopyrightChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setCopyrightText(newText);
    onCopyrightChange(newText);
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setContactInfo(newText);
    onContactChange(newText);
  };

  const handleNameColorUpdate = (color: string) => {
    setNameColor(color);
    onNameColorChange(color);
  };

  // 处理触摸事件
  const handleTouchStart = (e: React.TouchEvent) => {
    if (panelRef.current) {
      setIsDragging(true);
      const touch = e.touches[0];
      const rect = panelRef.current.getBoundingClientRect();
      setDragOffset({
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top
      });
    }
  };

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging) {
        const touch = e.touches[0];
        setPosition({
          x: touch.clientX - dragOffset.x,
          y: touch.clientY - dragOffset.y
        });
      }
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
    };

    if (isDragging) {
      document.addEventListener('touchmove', handleTouchMove, { passive: false });
      document.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging, dragOffset]);

  return (
    <div
      ref={panelRef}
      className={`fixed z-50 bg-white rounded-lg shadow-lg select-none
                 ${isCollapsed ? 'w-8' : 'w-[280px] md:w-80'}`}
      style={{
        left: `${Math.max(0, Math.min(position.x, window.innerWidth - (isCollapsed ? 32 : 320)))}px`,
        top: `${Math.max(0, Math.min(position.y, window.innerHeight - 100))}px`,
        cursor: isDragging ? 'grabbing' : 'grab',
        touchAction: 'none'
      }}
    >
      <div
        className={`bg-gray-100 rounded-lg cursor-grab flex items-center justify-center
                   ${isCollapsed ? 'h-8' : 'rounded-b-none p-1 md:p-2 mb-2 md:mb-4'}`}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        {!isCollapsed ? (
          <>
            <h3 className="text-sm md:text-base text-center font-bold text-gray-700 flex-1">Control Panel</h3>
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="p-0.5 md:p-1 hover:bg-gray-200 rounded-md transition-colors"
            >
              <ChevronLeft size={14} />
            </button>
          </>
        ) : (
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="w-6 h-6 flex items-center justify-center hover:bg-gray-200 rounded-md"
          >
            <ChevronRight size={14} />
          </button>
        )}
      </div>

      {!isCollapsed && (
        <div className="space-y-3 md:space-y-4 p-2 md:p-4">
          <div className="grid grid-cols-3 gap-1 md:gap-2">
            {diamondInputs.map((diamond, index) => (
              <input
                key={index}
                type="text"
                value={diamond}
                onChange={(e) => handleDiamondChange(index, e.target.value)}
                className="w-full p-1 md:p-2 text-xs md:text-sm border border-gray-300 rounded-md"
                placeholder={`${index + 1}`}
                maxLength={6}
              />
            ))}
          </div>

          <div className="flex gap-1 md:gap-2">
            <button
              type="button"
              onClick={handleLoadDiamonds}
              onTouchEnd={handleLoadDiamonds}
              className="flex-1 px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm bg-blue-500 text-white rounded-md hover:bg-blue-600"
            >
              Load Diamonds
            </button>
            <button
              type="button"
              onClick={() => {
                setDiamondInputs(DEFAULT_DIAMONDS_NULL);
                onDiamondsInput(DEFAULT_DIAMONDS_NULL);
              }}
              className="px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm bg-gray-500 text-white rounded-md hover:bg-gray-600"
            >
              Reset
            </button>
          </div>

          {/* 颜色设置区域 */}
          <div className="flex flex-col gap-2 bg-gray-50 rounded-lg p-2">
            <button
              onClick={() => setIsColorSectionCollapsed(!isColorSectionCollapsed)}
              className="flex items-center justify-between w-full text-sm text-gray-600 hover:bg-gray-100 rounded-md px-2 py-1"
            >
              <span>Color Settings</span>
              <ChevronRight
                size={16}
                className={`transform transition-transform ${isColorSectionCollapsed ? '' : 'rotate-90'}`}
              />
            </button>

            {/* 折叠内容 */}
            {!isColorSectionCollapsed && (
              <div className="space-y-4 pt-2">
                {/* 背景颜色设置 */}
                <div className="flex flex-col gap-2">
                  <label className="text-sm text-gray-600">Background Color</label>
                  <div className="grid grid-cols-5 gap-2 p-2 bg-gray-50 rounded-lg">
                    {PRESET_COLORS.map((color) => (
                      <div
                        key={color}
                        className={`w-8 h-8 rounded cursor-pointer border-2 hover:scale-110 transition-transform ${
                          color === bgColor ? 'border-blue-500' : 'border-gray-200'
                        }`}
                        style={{backgroundColor: color}}
                        onClick={() => handleColorUpdate(color)}
                      />
                    ))}
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      type="color"
                      value={bgColor}
                      onChange={(e) => handleColorUpdate(e.target.value)}
                      className="w-8 h-8 cursor-pointer rounded-r border-l-0"
                    />
                    <input
                      type="text"
                      value={bgColor}
                      onChange={(e) => handleColorUpdate(e.target.value)}
                      className="w-24 px-2 border rounded-l h-8 text-sm"
                    />
                  </div>
                </div>

                {/* 名称颜色设置 */}
                <div className="flex flex-col gap-2">
                  <label className="text-sm text-gray-600">Diamond Name Color</label>
                  <div className="grid grid-cols-5 gap-2 p-2 bg-gray-50 rounded-lg">
                    {PRESET_NAME_COLORS.map((color) => (
                      <div
                        key={color}
                        className={`w-8 h-8 rounded cursor-pointer border-2 hover:scale-110 transition-transform ${
                          color === nameColor ? 'border-blue-500' : 'border-gray-200'
                        }`}
                        style={{backgroundColor: color}}
                        onClick={() => handleNameColorUpdate(color)}
                      />
                    ))}
                  </div>
                  <div className="flex gap-2 items-center">
                    <input
                      type="color"
                      value={nameColor}
                      onChange={(e) => handleNameColorUpdate(e.target.value)}
                      className="w-8 h-8 cursor-pointer rounded-r border-l-0"
                    />
                    <input
                      type="text"
                      value={nameColor}
                      onChange={(e) => handleNameColorUpdate(e.target.value)}
                      className="w-24 px-2 border rounded-l h-8 text-sm"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* 版权信息输入 */}
          {/* <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-600">
              Copyright Text
            </label>
            <input
                type="text"
                value={copyrightText}
                onChange={handleCopyrightChange}
                className="w-full p-2 text-sm border border-gray-300 rounded-md"
                placeholder="Enter copyright text"
            />
          </div> */}

          {/* 联系方式输入 */}
          <div className="flex flex-col gap-2">
            <label className="text-sm text-gray-600">
              Contact Info
            </label>
            <input
              type="text"
              value={contactInfo}
              onChange={handleContactChange}
              className="w-full p-2 text-sm border border-gray-300 rounded-md"
              placeholder="Enter contact information"
            />
          </div>

          {/* 导出按钮 */}
          <button
              onClick={() => onExport(bgColor)}
              className="w-full px-4 py-2 bg-green-500 text-white rounded-lg
                       hover:bg-emerald-700 transition-colors"
          >
            Export Image
          </button>
        </div>
      )}
    </div>
  );
}
