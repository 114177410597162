import { PictureViewer } from "../../components/hacdset/picviewer";
import { ControlPanel } from "../../components/hacdset/control-panel";
import { useState } from "react";
import axios from "axios";
import { toPng } from 'html-to-image';
import {DiamondLifeGeneConvertVisualGene} from "../../scripts/diamondgene.js";

interface Diamond {
    number: string;
    name: string;
    visual_gene: string;
    fee: string;
}

export default function HACDSet() {
    const testurl="https://tool.myhacd.com"
    const [diamonds, setDiamonds] = useState<Diamond[]>([]);
    const [bgColor, setBgColor] = useState('#ffffff');
    const [copyrightText, setCopyrightText] = useState('© Copyright 2024 HACPOOL. All rights reserved.');
    const [contactInfo, setContactInfo] = useState('');
    const [nameColor, setNameColor] = useState('#1d4ed8');

    const handleDiamondsInput = (inputDiamonds: string[]) => {
        const fetchDiamondData = async () => {
            try {
                const names = inputDiamonds.join(',');
                const { data } = await axios.get(`${testurl}/api/diamond/views?names=${names}`);


                if (data.list) {
                    const updatedDiamonds = data.list.map((diamond: any) => ({
                        name: diamond.name,
                        number: diamond.number,
                        fee: diamond.bid_fee,
                        visual_gene: DiamondLifeGeneConvertVisualGene(diamond.life_gene, diamond.name)
                    }));

                    setDiamonds(updatedDiamonds);
                }
            } catch (error) {
                console.error('Error fetching diamond data:', error);
            }
        };
        fetchDiamondData();
    };

    const handleExport = (backgroundColor: string) => {
        const element = document.getElementById('diamond-viewer');
        if (!element) return;

        // 获取当前时间并格式化
        const now = new Date();
        const timestamp = `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
        
        // 获取实际元素的尺寸
        const rect = element.getBoundingClientRect();
        
        toPng(element, {
            backgroundColor,
            quality: 1.0,
            width: rect.width,
            height: rect.height,
            style: {
                margin: '0',
                padding: '0',
            },
            pixelRatio: 2,
        })
            .then((dataUrl) => {
                // 检查是否为移动设备
                const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                
                if (isMobile) {
                    // 移动端：创建一个临时的 a 标签并触发点击
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = `hacd_${timestamp}.png`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    // PC端：直接下载
                    const link = document.createElement('a');
                    link.download = `hacd_${timestamp}.png`;
                    link.href = dataUrl;
                    link.click();
                }
            })
            .catch((error) => {
                console.error('导出图片时发生错误:', error);
                // 显示错误提示
                alert('导出图片失败，请重试');
            });
    };

    const handleColorChange = (color: string) => {
        setBgColor(color);
    };

    const handleCopyrightChange = (text: string) => {
        setCopyrightText(text);
    };

    const handleContactChange = (text: string) => {
        setContactInfo(text);
    };

    const handleNameColorChange = (color: string) => {
        setNameColor(color);
    };

    return (
        <div className="flex flex-col items-center bg-gray-100 min-h-screen w-full py-8">
            <ControlPanel
                onExport={handleExport}
                onColorChange={handleColorChange}
                onDiamondsInput={handleDiamondsInput}
                onCopyrightChange={handleCopyrightChange}
                onContactChange={handleContactChange}
                onNameColorChange={handleNameColorChange}
            />
            <div className="bg-white shadow-lg">
                <PictureViewer
                    diamonds={diamonds}
                    backgroundColor={bgColor}
                    copyrightText={copyrightText}
                    contactInfo={contactInfo}
                    nameColor={nameColor}
                />
            </div>
        </div>
    )
}
